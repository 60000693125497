/* .container {
    padding: 0 !important;
    margin: 0 !important;
    min-height: 100vh;
} */
.ant-row-center {
    padding-top: 20px;
}

.ant-input-number-handler,.ant-input-number-handler-wrap {
display:none;
}
.ant-typography a{
padding-left: 100px;
left: 50%;
position: absolute;
}
.location_name {
    margin-bottom: 0;
}

.settingDrawer .ant-drawer-content-wrapper {
    width: 50% !important;
}

@media only screen and (max-width: 480px) {
    .settingDrawer .ant-drawer-content-wrapper {
        width: 100% !important;
    }
}
/*  @todo remove custom container styles  */
.container {
    padding: 0 50px;
    margin: auto;
    min-height: 100vh;
}

@media (max-width: 576px) {
    .container {
        width: 100%;
        padding: 0;
    }
}

@media (min-width: 576px) {
    .container {
        width: 540px;
    }
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .container {
        width: 720px;
    }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .container {
        width: 960px;
    }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .container {
        width: 1140px;
    }
}
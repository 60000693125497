@import '~@eb/styles/_variables.scss';

.site-layout-content {
  min-height: 301px;
  padding: 24px;
  background: #fff;
}

.logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

[data-theme="dark"] .site-layout-content {
  background: #141414;
}

.ant-skeleton-title, .ant-skeleton-content .ant-skeleton-paragraph > li {
  border-radius: 0 !important;
}

.ant-layout-header {
  padding: 0;
}

/*  @todo remove custom container styles  */
.header-container {
    min-height: 64px;
    width: 100%;
}

a.skip-main {
    left: -999px;
    position: absolute;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
    z-index: -999;
}

a:focus-visible {
    outline: 2px solid blue; 
}

.logo-container {
    width: 64px;
    height: 64px;
    text-align: center;
    background-color: white;
    cursor: pointer;
}

.header-right-box {
    margin: 0 !important;
}

.header-links {
    border-right: 1px solid white;
    padding-right: 5px;
    line-height: 16px;
    font-size: 14px;
}

.header-links a:focus {
    border: 1px solid green;
}

.header-links:last-child {
    border-right: 0;
}

.mobile-menu {
    background-color: rgb(33, 46, 53);
    top: 13px;
}

.mobile-menu li {
    padding: 14px 30px;
    border-bottom: 1px solid white;
}

.mobile-menu li:hover {
    /*background-color: rgba(33, 46, 53, 0.6);*/
}

.mobile-menu li a {
    color: white;
}

.mobile-menu li a:hover {
    color: black;
}

/*@media (max-width: 576px) {*/
/*    .header-container {*/
/*        width: 100%;*/
/*    }*/
/*}*/

/*@media (min-width: 576px) {*/
/*    .header-container {*/
/*        width: 540px;*/
/*    }*/
/*}*/

/*!* Small devices (tablets, 768px and up) *!*/
/*@media (min-width: 768px) {*/
/*    .header-container {*/
/*        width: 720px;*/
/*    }*/
/*}*/

/*!* Medium devices (desktops, 992px and up) *!*/
/*@media (min-width: 992px) {*/
/*    .header-container {*/
/*        width: 960px;*/
/*    }*/
/*}*/

/*!* Large devices (large desktops, 1200px and up) *!*/
/*@media (min-width: 1200px) {*/
/*    .header-container {*/
/*        width: 1140px;*/
/*    }
/}*/